import React, {useRef} from 'react';

import * as styles from './fade-in.module.css';

function FadeIn(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting)
        }
      });
    });
    observer.observe(domRef.current);
    }, []);
  return (
    <div className={`${styles.fadeInSection} ${isVisible ? styles.isVisible : ''}`} ref={domRef}>
      {props.children}
    </div>
  )
}

export default FadeIn;
