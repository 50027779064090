import React from "react";

import {Link} from "gatsby";
import Container from "../container/container";

import {responsiveTitle1} from "../typography.module.css";
import * as styles from "./artwork.module.css";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {getYear, parseISO} from "date-fns";
import FadeIn from "../fade-in/fade-in";

const Artwork = ({series}) => {

  function splitToChunks(array, parts) {
    let result = [];
    for (let i = parts; i > 0; i--) {
      result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
  }

  return (
    <Container>
      <h1 className={responsiveTitle1}>Artwork</h1>
      <div className={styles.row}>
        {series && splitToChunks(series, 4).map((ss, id) => (
          <div key={id} className={styles.column}>
            {ss.map(s => (
              <Link key={s.id} to={`/artwork/${s.slug.current}`} className={styles.link}>
                <FadeIn>
                  <div className={styles.container}>
                    <GatsbyImage alt={s.mainImage.alt}
                                 image={getImage(s.mainImage.asset.gatsbyImageData)}
                                 className={styles.image}/>
                    <div className={styles.middle}>
                      <div className={styles.text}>{s.title}</div>
                      <div className={styles.subText}>({getYear(parseISO(s.publishedAt))})</div>
                    </div>
                  </div>
                </FadeIn>
              </Link>
            ))}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Artwork;
