import React from "react";
import {graphql} from "gatsby";

import Layout from "../containers/layout";
import Artwork from "../components/artwork/artwork";
import SEO from "../components/seo";
import {mapEdgesToNodes} from "../lib/helpers";

export const query = graphql`
  query ArtworkPageQuery {
    series: allSanitySeries(
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          _rawPaintings
          _rawDescription
          mainImage {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          publishedAt
        }
      }
    }
  }
`;

const ArtworkPage = props => {
  const {data} = props;
  const series = data && data.series && mapEdgesToNodes(data.series);
  return (
    <Layout>
      <SEO title="Artwork"/>
      <Artwork series={series}/>
    </Layout>
  );
};

export default ArtworkPage;
